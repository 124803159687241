import { TokenAmount, Pair, Currency /* , Token, ChainId */ } from '@deft/sdk'
import { useMemo } from 'react'
import { useActiveWeb3React } from '../hooks'
import { useSingleContractMultipleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useDeftDex } from '../hooks/useContract'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairIDs = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getPairID(tokenA, tokenB) : undefined
      }),
    [tokens]
  )
  console.log('PairIDs: ', pairIDs)
  // console.log(
  //   'getPairID: ',
  //   Pair.getPairID(
  //     new Token(ChainId.CROSSFI_TESTNET, '0xd58E7E70fD2A21b918E7448c69F5108e6C8e3628', 18),
  //     new Token(ChainId.CROSSFI_TESTNET, '0x62F59422c5F76eee92A0425E6aA54426E43ADEAD', 18)
  //   )
  // )
  const results = useSingleContractMultipleData(
    useDeftDex(),
    'getReserves',
    pairIDs.map(pairID => [pairID])
  )

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const pairs = usePairs([[tokenA, tokenB]])?.[0]
  return pairs?.[0] ? pairs : [PairState.NOT_EXISTS, null]
}
