import { CurrencyAmount, XFI, JSBI } from '@deft/sdk'
import { MIN_XFI } from '../constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency === XFI) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_XFI)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_XFI))
    } else {
      return CurrencyAmount.ether(JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
