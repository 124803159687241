import { Interface } from '@ethersproject/abi'
import CFC20_ABI from './cfc20.json'
import CFC20_BYTES32_ABI from './cfc20_bytes32.json'

const CFC20_INTERFACE = new Interface(CFC20_ABI)

const CFC20_BYTES32_INTERFACE = new Interface(CFC20_BYTES32_ABI)

export default CFC20_INTERFACE
export { CFC20_ABI, CFC20_BYTES32_INTERFACE, CFC20_BYTES32_ABI }
