import { Currency, XFI, Token } from '@deft/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import deftTokenList from '../../assets/deftTokenList.json'

const getTokenLogoURL = (address: string): string => {
  return deftTokenList.tokens.find(token => token.address === address)?.logoURI ?? ''
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === XFI) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === XFI) {
    return (
      <StyledEthereumLogo
        src="https://raw.githubusercontent.com/DeftFinance/token-list/crossfi-testnet/assets/asset_XFI.png"
        size={size}
        style={style}
      />
    )
  }
  if (currency?.symbol === 'WXFI') {
    return (
      <StyledLogo
        srcs={['https://raw.githubusercontent.com/DeftFinance/token-list/crossfi-testnet/assets/asset_WXFI.png']}
        size={size}
        alt="WXFI logo"
        style={style}
      />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
