import { DEFT_DEX, Currency, CurrencyAmount, XFI, JSBI, Token, TokenAmount, ChainId } from '@deft/sdk'
import { useMemo } from 'react'
import { useAllTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import { isAddress } from '../../utils'
import CFC20_INTERFACE from '../../constants/abis/cfc20'
import IDEFTDEX from '../../constants/abis/deftdex'
/**
 * Returns a map of the given addresses to their eventually consistent XFI balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, CFC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[token.address] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useERC6909BalanceWithLoadingIndicator(
  pairIDs?: string[],
  address?: string
): [{ [pairID: string]: TokenAmount | undefined }, boolean] {
  console.log('useERC6909BalanceWithLoadingIndicator::', pairIDs)
  console.log('useERC6909BalanceWithLoadingIndicator::', address)

  function GetData(pairID: string) {
    return useMultipleContractSingleData([DEFT_DEX], IDEFTDEX, 'balanceOf', [pairID, address])
  }

  const balances = pairIDs?.map(pairID => GetData(pairID)[0])
  console.log('b: ', balances)

  const anyLoading: boolean = useMemo(
    () => (balances && balances[0] ? balances.some(callState => callState.loading) : false),
    [balances]
  )

  console.log('a: ', anyLoading)

  return [
    useMemo(
      () =>
        address && pairIDs && pairIDs?.length !== 0
          ? pairIDs?.reduce<{ [pairID: string]: TokenAmount | undefined }>((memo, pairID, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) memo[pairID] = new TokenAmount(new Token(ChainId.CROSSFI_TESTNET, DEFT_DEX, 18), amount)
              return memo
            }, {})
          : {},
      [address, pairIDs, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useERC6909Balances(
  pairIDs?: string[],
  account?: string
): { [pairID: string]: TokenAmount | undefined } {
  return useERC6909BalanceWithLoadingIndicator(pairIDs, account)[0]
}

export function useERC6909Balance(pairID?: string, account?: string): TokenAmount | undefined {
  const bal = useERC6909Balances(pairID ? [pairID] : undefined, account)
  if (pairID) return bal[pairID]
  return undefined
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies
  ])

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some(currency => currency === XFI) ?? false, [currencies])
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === XFI) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
