import { Contract } from '@ethersproject/contracts'
import { DEFT_DEX, WXFI } from '@deft/sdk'
import { useMemo } from 'react'
import { CFC20_BYTES32_ABI } from '../constants/abis/cfc20'
import CFC20_ABI from '../constants/abis/cfc20.json'
import WXFI_ABI from '../constants/abis/wxfi.json'
import { MULTICALL_ABI } from '../constants/multicall'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { IDEFTDEX } from '../constants/abis/deftdex'
// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, CFC20_ABI, withSignerIfPossible)
}

export function useWXFIContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WXFI[chainId].address : undefined, WXFI_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, CFC20_BYTES32_ABI, withSignerIfPossible)
}

export function useDeftDex(): Contract | null {
  return useContract(DEFT_DEX, IDEFTDEX, false)
}

export function useMulticallContract(): Contract | null {
  return useContract('0x4FbF3D3F0D32a86f7585B83985Da8E00DCB3eb82', MULTICALL_ABI, false)
}
