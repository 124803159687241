import { BigNumber } from '@ethersproject/bignumber'
import { DEFT_DEX, Token, TokenAmount, ChainId } from '@deft/sdk'
import { useDeftDex, useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export function useERC6909TotalSupply(pairID?: string): TokenAmount | undefined {
  const deftDex = useDeftDex()
  console.log('deftDex: ', deftDex)
  console.log('PAO: ', [pairID])

  const totalSupply: BigNumber = useSingleCallResult(pairID === '' ? undefined : deftDex, 'totalSupply', [pairID])
    ?.result?.[0]

  return pairID !== '' && totalSupply
    ? new TokenAmount(new Token(ChainId.CROSSFI_TESTNET, DEFT_DEX, 18), totalSupply.toString())
    : undefined
}
